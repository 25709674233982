import { Button } from "components/Button";
import { AppContext } from "context/app";
import { useTranslationContext } from "context/translation";
import { useFlavorsAndBottlesAnalytics } from "hooks/analytics/useFlavorsAndBottlesAnalytics";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, Fragment, useContext, useMemo } from "react";
import {
  DatoProductForCategoryPageFragment,
  LabelBySlugQuery,
  ProductCategoryFragment,
} from "services/datocms/generated";
import {
  GetItemsByGtinQuery,
  useDynamicBundlesQuery,
} from "services/graphql/generated";
import { CategoryHero } from "./CategoryHero";
import { PromotionalCard } from "./PromotionalCard";
import { SingleProduct } from "./SingleProduct";

interface FlavorsListingProps {
  category?: ProductCategoryFragment;
  products: DatoProductForCategoryPageFragment[];
  pepdirectProducts?: NonNullable<GetItemsByGtinQuery["items"]>["items"];
  loading?: boolean;
  showLabels?: boolean;
}

export const FlavorsListing: FC<FlavorsListingProps> = ({
  category,
  pepdirectProducts,
  products,
  loading = false,
  showLabels = true,
}) => {
  const { filterBy, all } = useTranslationContext();
  const router = useRouter();

  const {
    analyticsCategoryName,
    analyticsName,
    description,
    heroImage,
    heroImageMobile,
    heroText,
    promotionalCard,
    plpItemBackgroundColor,
  } = category || {};

  const { id: labelSlug } = router.query;

  const { data: allDynamicBundles } = useDynamicBundlesQuery();

  const allLabels = useMemo(() => {
    const labelsSet = new Set<string>();
    const allLabels: NonNullable<LabelBySlugQuery["productLabel"]>[] = [];
    products.forEach((product) => {
      product.labels.forEach((label) => {
        const slug = label.slug as string;
        if (!labelsSet.has(slug) && showLabels) {
          labelsSet.add(slug);
          allLabels.push(label);
        }
      });
    });
    return allLabels;
  }, [products, showLabels]);

  const selectedLabelSlug =
    allLabels.find((label) => label.slug === labelSlug)?.slug ?? all;

  const { tenant } = useContext(AppContext);
  const currency = tenant?.checkout?.paymentOptions?.defaultCurrencyCode || "";

  const { handleLogging } = useFlavorsAndBottlesAnalytics(
    currency,
    analyticsName,
    analyticsCategoryName,
    products,
    pepdirectProducts,
    allDynamicBundles ?? {},
  );

  const filteredProducts =
    selectedLabelSlug === all
      ? products
      : products.filter((product) =>
          product.labels.find((label) => label.slug === selectedLabelSlug),
        );

  return (
    <>
      <CategoryHero
        heroImage={heroImage?.responsiveImage}
        heroImageMobile={heroImageMobile?.responsiveImage}
        heroText={heroText}
        description={description}
      />
      <div className="container p-6 flex flex-col gap-10">
        {allLabels.length > 0 && (
          <div>
            <p className="text-sm text-gray-500 mb-1">{filterBy}</p>
            <div className="flex flex-wrap gap-2 max-w-screen">
              {!allLabels.some((item) => item.label === all) && (
                <Link shallow scroll={false} href={`/${category?.slug}`}>
                  <Button
                    size="small"
                    variant={selectedLabelSlug === all ? "filled" : "outlined"}
                  >
                    {all}
                  </Button>
                </Link>
              )}
              {allLabels.map((label) => (
                <Link
                  shallow
                  scroll={false}
                  key={label.id}
                  href={`/${category?.slug}/${label.slug}`}
                >
                  <Button
                    size="small"
                    variant={
                      label.slug === selectedLabelSlug?.toLocaleLowerCase()
                        ? "filled"
                        : "outlined"
                    }
                  >
                    {label.label}
                  </Button>
                </Link>
              ))}
            </div>
          </div>
        )}
        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6">
          {filteredProducts.map((product, index) => (
            <Fragment key={index}>
              {promotionalCard && promotionalCard.cardPosition === index && (
                <PromotionalCard cardData={promotionalCard} />
              )}
              <SingleProduct
                datoProduct={product}
                pepdirectProduct={pepdirectProducts?.find(
                  (item) => item.gtin === product?.gtin,
                )}
                pepdirectBundle={allDynamicBundles?.dynamicBundles?.find(
                  (bundle) => bundle?.tag === product?.bundleTag,
                )}
                handleLogging={handleLogging}
                analytics={{
                  itemCategory: category?.analyticsCategoryName ?? "",
                  itemListName: category?.analyticsName ?? "",
                }}
                loading={loading}
                categoryBgColor={plpItemBackgroundColor?.hex ?? ""}
              />
            </Fragment>
          ))}
        </div>
      </div>
    </>
  );
};
